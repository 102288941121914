<template>
  <b-form-group>
    <validation-provider
      v-slot="{ valid, errors, required }"
      :vid="vid"
      :name="$attrs.name"
      :rules="rules"
    >
      <template v-if="type === 'email' || type === 'text'">
        <label v-if="$attrs.label">{{ $attrs.label }} <span>{{ required ? ' *' : '' }}</span></label>
        <b-form-input
          v-model="innerValue"
          :disabled="disabled"
          v-bind="$attrs"
          :size="fieldSize"
          :state="errors[0] ? false : valid ? true : null"
        />
        <b-form-invalid-feedback>{{
          errors[0]
        }}</b-form-invalid-feedback>
      </template>

      <template v-if="type === 'number'">
        <label v-if="$attrs.label">{{ $attrs.label }} <span>{{ required ? ' *' : '' }}</span></label>
        <cleave
          :id="$attrs.key"
          v-model="innerValue"
          :disabled="disabled"
          :class="[{'is-invalid': errors.length > 0 },{'form-control-sm': fieldSize === 'sm'},{'form-control-lg': fieldSize === 'lg'}, {'form-control-md': fieldSize === 'md'}]"
          class="form-control"
          v-bind="$attrs"
          :size="fieldSize"
          :raw="true"
          :options="options.number"
        />
        <b-form-invalid-feedback>{{
          errors[0]
        }}</b-form-invalid-feedback>
      </template>

      <template v-if="type === 'money'">
        <label v-if="$attrs.label">{{ $attrs.label }} <span>{{ required ? ' *' : '' }}</span></label>
        <b-input-group :class="[{'is-invalid': errors.length > 0 },{'input-group-sm': fieldSize === 'sm'},{'input-group-lg': fieldSize === 'lg'}, {'input-group-md': fieldSize === 'md'}]">
          <b-input-group-prepend is-text>
            {{ CurrencyLabel }}
          </b-input-group-prepend>
          <cleave
            :id="$attrs.key"
            v-model="innerValue"
            :disabled="disabled"
            :class="[{'is-invalid': errors.length > 0 },{'form-control-sm': fieldSize === 'sm'},{'form-control-lg': fieldSize === 'lg'}, {'form-control-md': fieldSize === 'md'}]"
            class="form-control"
            v-bind="$attrs"
            :size="fieldSize"
            :raw="true"
            :options="options.number"
          />
        </b-input-group>
        <b-form-invalid-feedback>{{
          errors[0]
        }}</b-form-invalid-feedback>
      </template>

      <template v-if="type === 'textarea'">
        <label v-if="$attrs.label">{{ $attrs.label }} <span>{{ required ? ' *' : '' }}</span></label>
        <b-form-textarea
          v-model="innerValue"
          rows="3"
          :disabled="disabled"
          v-bind="$attrs"
          :size="fieldSize"
          :state="errors[0] ? false : valid ? true : null"
        />
        <b-form-invalid-feedback>{{
          errors[0]
        }}</b-form-invalid-feedback>
      </template>

      <template v-if="type === 'phone'">
        <label v-if="$attrs.label">{{ $attrs.label }} <span>{{ required ? ' *' : '' }}</span></label>
        <b-input-group :class="[{'is-invalid': errors.length > 0 },{'input-group-sm': fieldSize === 'sm'},{'input-group-lg': fieldSize === 'lg'}, {'input-group-md': fieldSize === 'md'}]">
          <b-input-group-prepend
            is-text
          >
            MY (+60)
          </b-input-group-prepend>
          <cleave
            :id="$attrs.key"
            v-model="innerValue"
            :disabled="disabled"
            :class="[{'is-invalid': errors.length > 0 },{'form-control-sm': fieldSize === 'sm'},{'form-control-lg': fieldSize === 'lg'}, {'form-control-md': fieldSize === 'md'}]"
            class="form-control"
            v-bind="$attrs"
            :size="fieldSize"
            :raw="false"
            :options="options.phone"
            placeholder="12 3456 789"
          />
        </b-input-group>

        <b-form-invalid-feedback>{{
          errors[0]
        }}</b-form-invalid-feedback>
      </template>

      <template v-if="type === 'password'">
        <label v-if="$attrs.label">{{ $attrs.label }} <span>{{ required ? ' *' : '' }}</span></label>
        <b-input-group
          :class="[{'is-invalid': errors.length > 0 }]"
        >
          <b-form-input
            v-model="innerValue"
            :disabled="disabled"
            :type="passwordFieldType"
            v-bind="$attrs"
            :size="fieldSize"
            :state="errors[0] ? false : valid ? true : null"
          />
          <b-input-group-append
            is-text
          >
            <feather-icon
              :icon="passwordToggleIcon"
              class="cursor-pointer"
              @click="togglePasswordVisibility"
            />
          </b-input-group-append>
        </b-input-group>

        <small class="text-danger">{{ errors[0] }}</small>
      </template>

      <template v-else-if="type === 'vselect'">
        <label v-if="$attrs.label">{{ $attrs.label }} <span>{{ required ? ' *' : '' }}</span></label>
        <v-select
          :key="$attrs.key"
          v-model="innerValue"
          :disabled="disabled"
          placeholder="Please select"
          :reduce="innerValue => innerValue[reduceValue]"
          :class="[{'is-invalid': errors.length > 0 },{'select-size-sm': fieldSize === 'sm'},{'select-size-lg': fieldSize === 'lg'}, {'select-size-md': fieldSize === 'md'}]"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="selectOption"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </template>

      <template v-else-if="type === 'datepicker'">
        <label v-if="$attrs.label">{{ $attrs.label }} <span>{{ required ? ' *' : '' }}</span></label>
        <flat-pickr
          :key="$attrs.key"
          v-model="innerValue"
          :disabled="disabled"
          :config="datepickerOption"
          :class="[{'is-invalid': errors.length > 0 },{'form-control-sm': fieldSize === 'sm'},{'form-control-lg': fieldSize === 'lg'}, {'form-control-md': fieldSize === 'md'}]"
          class="form-control"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </template>

      <template v-else-if="type === 'spinbutton'">
        <label v-if="$attrs.label">{{ $attrs.label }} <span>{{ required ? ' *' : '' }}</span></label>
        <b-form-spinbutton
          :key="$attrs.key"
          v-model="innerValue"
          :disabled="disabled"
          :size="fieldSize"
          :class="[{'is-invalid': errors.length > 0 }]"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </template>

      <template v-else-if="type === 'timepicker'">
        <label v-if="$attrs.label">{{ $attrs.label }} <span>{{ required ? ' *' : '' }}</span></label>
        <flat-pickr
          :key="$attrs.key"
          v-model="innerValue"
          :disabled="disabled"
          class="form-control"
          :class="[{'is-invalid': errors.length > 0 },{'form-control-sm': fieldSize === 'sm'},{'form-control-lg': fieldSize === 'lg'}, {'form-control-md': fieldSize === 'md'}]"
          :config="{ enableSeconds: true, enableTime: true, noCalendar: true, dateFormat: 'H:i:s',}"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </template>

      <template v-else-if="type === 'datetimepicker'">
        <label v-if="$attrs.label">{{ $attrs.label }} <span>{{ required ? ' *' : '' }}</span></label>
        <flat-pickr
          :key="$attrs.key"
          v-model="innerValue"
          :disabled="disabled"
          :class="[{'is-invalid': errors.length > 0 },{'form-control-sm': fieldSize === 'sm'},{'form-control-lg': fieldSize === 'lg'}, {'form-control-md': fieldSize === 'md'}]"
          class="form-control"
          :config="{ enableTime: true,noCalendar: false,dateFormat: 'Y-m-d H:i'}"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </template>

      <template v-else-if="type === 'quill'">
        <div>
          <label v-if="$attrs.label">{{ $attrs.label }} <span>{{ required ? ' *' : '' }}</span></label>
          <quill-editor
            :key="$attrs.key"
            v-model="innerValue"
            :disabled="disabled"
            :options="quiltOpts"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </div>

      </template>

      <template v-if="type === 'switch'">
        <label>{{ $attrs.label }}</label><br>
        <b-form-checkbox
          :id="$attrs.key"
          v-model="innerValue"
          :disabled="disabled"
          :checked="false"
          switch
          inline
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>

        <small class="text-danger">{{ errors[0] }}</small>
      </template>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.my'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'BaseInput',
  components: {
    ValidationProvider,
    vSelect,
    flatPickr,
    quillEditor,
    Cleave,
  },
  mixins: [togglePasswordVisibility],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
    },
    // must be included in props
    rules: {
      type: [Object, String],
      default: '',
    },
    datepickerOption: {
      type: Object,
      default() {
        return {
          altInput: true,
          altFormat: 'Y-m-d',
          dateFormat: 'Z',
        }
      },
    },
    trueLabel: {
      type: String,
      default: null,
    },
    reduceValue: {
      type: String,
      default: 'value',
    },
    falseLabel: {
      type: String,
      default: null,
    },
    value: {
      type: null,
    },
    fieldSize: {
      type: String,
      default: 'sm',
    },
    selectOption: {
      type: Array,
      default() {
        return []
      },
    },
    quillOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data: () => ({
    innerValue: null,
    CurrencyLabel: 'MYR',
    options: {
      number: {
        numeral: true,
        numeralDecimalMark: '.',
        numeralDecimalScale: 2,
      },
      phone: {
        phone: true,
        phoneRegionCode: 'MY',
      },
    },
  }),
  computed: {
    quiltOpts() {
      if (this.quillOptions !== null) {
        return this.quillOptions
      }
      return {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image', 'video'],
          ],
        },
      }
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>

<style lang="scss">
.quill-editor { min-height: 150px }
.ql-container, .ql-editor {
  min-height: inherit;
}
</style>
